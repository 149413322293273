* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

textarea {
  resize: vertical;
}

:root {
  --base-gap: 20px;
  --gap: var(--base-gap);
}
