.home-main-box {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 10px;
  width: 100%;
  height: 100vh;
  /* height: 1fr; */
  /* padding: var(--gap); */
}

.divider {
  width: 0.5px;
  background-color: #d9d9d9;
}

.box {
  background-color: #4caf50;
  color: white;
  padding: 20px;
  text-align: center;
}
